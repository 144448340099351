@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');



body {
    font-family: Inter, sans-serif;
    background-color: rgb(250, 250, 250);
}

.hide-scroll-bar::-webkit-scrollbar {
    display: none;
}

.hide-scroll-bar {

    scrollbar-width: none;
}